<template>

    <div>

        <v-container fluid>

            <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Loan Calculator</v-subheader>
            </v-row>

            <v-form ref="form">
                <v-row align="center">
                    <v-text-field
                        ref="companyInput"
                        v-model="companyInput"
                        :rules="[rules.required]"
                        color="csb_yellow"
                        label="Company Name"
                        readonly>
                    </v-text-field>
                </v-row>

                <v-row align="center" style="margin-top: -6px; margin-bottom: 16px;">
                    <v-text-field
                        ref="nthp"
                        pattern="\d*" 
                        type="tel"
                        :rules="[rules.required, rules.nthp]"
                        color="csb_yellow"
                        prefix="₱"
                        v-model="nthp"
                        label="How much is your net take home pay?"
                        @keydown="isAmount('nthp'), onInputNthp()"
                        hide-details="auto">
                        
                    </v-text-field>
                </v-row>

                <v-row align="center">
                    <v-text-field
                        ref="loan_amount_input"
                        pattern="\d*" 
                        type="tel"
                        :rules="[rules.required, rules.loan_amount_input]"
                        color="csb_yellow"
                        prefix="₱"
                        v-model="loan_amount_input"
                        label="How much is your preferred loan amount?"
                        hide-details
                        :disabled="!checkValidity()"
                        @keydown="isAmount('loan_amount_input'), onInputLoanAmount()">
                    </v-text-field>
                </v-row>
            </v-form>

            <v-row justify="center" align="center" class='helper' >
                <v-col style="padding: 0px !important; color: black;">{{min_loan_amount | formatAmount}}.00</v-col>
                <v-col style="padding: 0px !important; text-align: center; color: rgb(117, 117, 117);" cols="7">
                    <span v-if="!checkValidity()">{{ warningMessage }}</span>
                    <span v-if="!checkLoanAmount('max') && this.loan_amount_input">Exceeds maximum loanable amount</span>
                    <span v-if="!checkLoanAmount('min') && this.loan_amount_input">Exceeds minimum loanable amount</span>
                </v-col>
                <v-col style="padding: 0px !important; color: black; text-align: right;">{{max_loan_amount | formatAmount}}.00</v-col>
            </v-row>
            <br>

            <v-row justify="center" align="center" class='helper' >
                <p class="label-text" style="font-size:13.5px;color: #757575;">What is your preferred payment term (in months)?</p>
            </v-row>
            
            <v-row class="radio-cont">
                <v-col class="radio-custom" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <input id="12_month" type="radio" v-model="payment_term" value="12" hidden checked>
                    <label class="" for="12_month">
                        12
                    </label>
                </v-col>
                <v-col class="radio-custom" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <input id="18_month" type="radio" v-model="payment_term" value="18" hidden>
                    <label class="" for="18_month">
                        18
                    </label>
                </v-col>
                <v-col class="radio-custom" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <input id="24_month" type="radio" v-model="payment_term" value="24" hidden>
                    <label class="" for="24_month">
                        24
                    </label>
                </v-col>
                <v-col class="radio-custom" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <input id="36_month" type="radio" v-model="payment_term" value="36" hidden>
                    <label class="" for="36_month">
                        36
                    </label>
                </v-col>
                <v-col class="radio-custom" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <input id="48_month" type="radio" v-model="payment_term" value="48" hidden>
                    <label class="" for="48_month">
                        48
                    </label>
                </v-col>
                <v-col class="radio-custom" style="padding-left: 0px !important; padding-right: 0px !important;">
                    <input id="60_month" type="radio" v-model="payment_term" value="60" hidden>
                    <label class="" for="60_month">
                        60
                    </label>
                </v-col>
            </v-row>


            <v-row class="calc-details">
                <v-col class="radio-cont">
                    <div class="calc-details-text">
                        Monthly Amortization
                    </div>
                    {{monthly_amo | formatAmount}}
                    <br><br>
                    <div class="calc-details-text">
                        Loan Amount
                    </div>
                    {{loan_amount | formatAmount}}
                </v-col>
                <v-col class="radio-cont">
                    <div class="calc-details-text">
                        Bank Charge
                    </div>
                     {{processing_fee | formatAmount}}
                    <br><br>
                    <div class="calc-details-text">
                        Net Proceeds
                    </div>
                    {{net_proceeds | formatAmount}}
                </v-col>
            </v-row>

            <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Promo Code</v-subheader>
            </v-row>

            <v-row align="center">
                <v-text-field
                    ref="promo_code"
                    color="csb_yellow"
                    v-model="promo_code"
                    label="Please enter promo code here"
                    hide-details>
                </v-text-field>
            </v-row>

            <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                >
                {{ text }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Close
                    </v-btn>
                </template>
            </v-snackbar>
            
        </v-container>

        <p class="label-text mt-8" style="text-align: center; font-size:12px;color: #757575;">Processing fee is inclusive of Taxes</p>
        
        <v-row  align="center" justify="space-around" class="bottom-nav">

           
            <v-col class="bottom-nav-item">
                <v-btn @click="handleBack()" depressed class="bottom-nav-btn back-btn">
                    Back
                </v-btn>
            </v-col>
            <v-col class="bottom-nav-item">
                <v-btn @click="handleNext()" depressed class="bottom-nav-btn">
                    Next
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    computed: {

        ...mapGetters([
            'company',
            'profileFormIsValid',
            'addressFormIsValid',
            'fb_messengerid',
 
        ]),

        warningMessage(){
            if(!this.isMounted)
                return;

            if(!this.companyInput && ((this.nthp && (this.convertNumber(this.nthp) >= this.minimum_nthp)) == false)){
                return 'Enter Net take home pay first'
            } else if(!this.companyInput) {
                return 'Enter Company first'
            } else {
                return 'Enter Net take home pay first'
            }
        },
    },

    beforeRouteLeave(to, from, next) {

        if(to.name == 'Company'){

            var calculatorFormIsValid = this.$refs.form.validate()
            this.$store.dispatch('user/calculatorFormIsValid', calculatorFormIsValid)
            next()
            
        } else {
     
            if(this.$refs.form.validate()){
                
                if(to.name == 'Profile'){
                    next()
                } else if(to.name == 'Address'){
                    if(this.profileFormIsValid == true){
                        next();
                    }
                } else if(to.name == 'Attachment'){
                    if(this.profileFormIsValid && this.addressFormIsValid){
                        next();
                    }
                }
                
                this.$store.dispatch('user/calculator', this.$data)

            }
        }

    },
  
    data: function() {
        return {
            isMounted: false,

            // company input
            companyId: '',
            companyInput: '',
            companies: [],
            search: null,
            isLoading: false,

            nthp: '',
            minimum_nthp: 0,
            loan_amount_input: '',
            payment_term :'12',

            loan_amount: "0.00",
            monthly_amo: "0.00",
            processing_fee: "0.00",
            net_proceeds: "0.00",

            min_loan_amount: 0,
            max_loan_amount: 0,
            rate: '',
            bank_charge: 0,

            company_sys_id: null,

            //rules
            rules: {
                required: v => !!v || 'Required.',
                nthp: v => (this.convertNumber(v) >= this.minimum_nthp) || 'Min of ₱ ' + this.minimum_nthp.toLocaleString(),
                loan_amount_input: () => this.checkLoanAmount() == true
                
            },

            snackbar: false,
            text: 'Only images are accepted as signature.',
            timeout: 2000,

            all_companies: [],

            promo_code: '',
        }
    },

    watch: {

        payment_term: function(){
            this.minimum_nthp = 0
            this.getMaxLoanAmount()
            this.getLoanDetails()
            this.$refs.form.validate()
        }

    },
    filters: {
        formatAmount: function(value){
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
    methods:{
        handleBack(){

            this.$store.dispatch('user/navVisibility', false) // show nav
            this.$router.push({ path: '/company' })

        },
        handleNext(){
           
            this.$router.push({ path: '/profile/index' })
       
        },
        getCompanyId(){
            var name = this.companies.filter(i => i.company == this.companyInput )
            return name[0].sys_id
        },
        onInputNthp(){
            this.getMaxLoanAmount()
            this.getLoanDetails()
        },
        onInputLoanAmount(){
            this.getMaxLoanAmount()
            this.getLoanDetails()
        },
        checkValidity: function(){
            if(!this.isMounted)
                return;

            if(this.companyInput && this.nthp && (this.convertNumber(this.nthp) >= this.minimum_nthp)){
                return true
            }
            else 
                return false
        },
        checkLoanAmount: function(val=''){
            var loan_amount_input = (this.loan_amount_input) ? this.convertNumber(this.loan_amount_input) : ''
            var ok_min = (loan_amount_input >= this.min_loan_amount)
            var ok_max = (loan_amount_input <= this.max_loan_amount)
            if(this.max_loan_amount!=0 ){
                if(val){
                    if(val=='min')
                        return ok_min
                    else if(val=='max')
                        return ok_max
                }else{
                    return (ok_min && ok_max)
                }
            }
            else return false
        },
        getMaxLoanAmount: function(){

            if( this.$refs.companyInput.validate() ){
                
                //var max_monthly_amo = this.convertNumber(this.convertNumber(this.nthp)) - 5000 // for suc, lgu, deped
                var max_monthly_amo = this.convertNumber(this.nthp) * 0.3 // for puc & agency
                var temp_companies = []
                
                this.rate = ''
                //get values which has same company name
                this.companies.forEach((value) => {
                    if(value.company == this.companyInput){
                        temp_companies.push(value)
                    }
                });
                //Find rate
                temp_companies.forEach((value) => {
                    if(value.term == this.payment_term){
                        this.rate = value.interest_rate
                        this.bank_charge = value.total_bank_charge
                        this.company_sys_id = value.sys_id
                    }
                });
                if(!this.rate){
                    temp_companies.forEach((value) => {
                        if(value.term == 'any'){
                            this.rate = value.interest_rate
                            this.bank_charge = value.total_bank_charge
                            this.company_sys_id = value.sys_id
                        }
                    });
                }
                this.rate = this.convertNumber(this.rate) / 100
                this.max_loan_amount = this.computeMaxLoanAmount(this.rate, this.payment_term, max_monthly_amo)
                if(this.max_loan_amount < 25000){
                    var temp = this.computeMonthlyAmo(this.rate, this.payment_term, 25000)
                    this.minimum_nthp = Math.ceil(((temp / 3) * 10) / 100) * 100
                    
                    this.max_loan_amount = 0
                    this.min_loan_amount = 0
                    this.loan_amount_input = null
                    this.$refs.loan_amount_input.reset()
                   
                } else {
                    this.max_loan_amount = (this.max_loan_amount > 1500000) ? 1500000 : this.max_loan_amount
                    this.min_loan_amount = 25000

                }

                // validate form after assigning value to minimum nthp
                if(this.nthp !== '' && this.nthp !== null && this.nthp !== undefined){
                    this.$refs.form.validate()
                }
            }else{
                this.max_loan_amount = 0
                this.min_loan_amount = 0
                this.loan_amount_input = null
                this.minimum_nthp = 0
                this.$refs.nthp.resetValidation()
            }
        },
        computeMaxLoanAmount: function(rate, nper, pmt){
            rate = rate / 12;
            var amount = pmt / rate * (1 - Math.pow(1 + rate, -nper));
            //round DOWN neareast thousand
            amount = Math.floor(amount / 1000) * 1000

            return amount;
        },
        getLoanDetails: function() {
            if( this.checkValidity() && this.loan_amount_input && this.checkLoanAmount() ){
                var loan_amount_input = this.convertNumber(this.loan_amount_input)

                this.loan_amount = loan_amount_input.toFixed(2)
                this.monthly_amo = this.computeMonthlyAmo(this.rate, this.payment_term, loan_amount_input)
                this.processing_fee = ( loan_amount_input * (this.convertNumber(this.bank_charge) / 100) ).toFixed(2)
                this.net_proceeds = (loan_amount_input - this.processing_fee).toFixed(2)
                // this.convertNumber(this.nthp)_after_deduction = (this.convertNumber(this.convertNumber(this.nthp)) - this.convertNumber(this.monthly_amo)).toFixed(2)

            }else{
                this.loan_amount = "0.00"
                this.monthly_amo = "0.00"
                this.processing_fee = "0.00"
                this.net_proceeds = "0.00"
                // this.convertNumber(this.nthp)_after_deduction  = "0.00"
            }
        },
        computeMonthlyAmo(rate, term, loan_amount){ 
            rate = rate / 12
            term = term * -1
            var monthly_amo = ( (loan_amount * rate) / (1 - (Math.pow( 1 + rate, term))) )
            
            return monthly_amo.toFixed(2)
        },
        convertNumber: function(value){
            return  Number(value.replace(/[^0-9.-]+/g,""));
        },
        isAmount: function(field){
            var charCode = (event.which) ? event.which : event.keyCode;
            
            if ((charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) && charCode !== 46 && charCode !== 188 && charCode !== 190) {
                event.preventDefault();
            } else {
                event.preventDefault();
                var valueString = event.target.value

                if(charCode==8 || charCode==46)
                    valueString = valueString.slice(0, -1)
                else if(field=='nthp'){
                    valueString = valueString + event.key
                    if(valueString.length > 7) return false
                }
                else if(field=='loan_amount_input')
                    valueString = valueString + event.key

                var str = valueString.replace(/[^0-9]/g,'')

                if(field == 'nthp' || field == 'loan_amount_input'){
                    var formattedString = str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    this[field] = formattedString
                } else
                    this[field] = str
            }
        },
    },
    activated(){
        this.companyInput = this.company.companyInput
        this.companies = this.company.companies
        this.getMaxLoanAmount()
        this.isMounted = true
    },
}
</script>